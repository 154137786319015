<template>
  <div>
    <div class="btn btn-link">
      <fa-icon v-if="serviceModule.enabled" icon="cogs" @click="openModuleConfiguration" />
    </div>
    <b-modal :ref="`${serviceModule.key}conf`" size="lg" no-enforce-focus hide-header>
      <b-card title="Notification options">
        <form @submit.prevent="enableModuleLocal">
          <div class="form-group">
            <label for="replyToAddress">Default reply-to address</label>
            <b-form-input id="replyToAddress" v-model="conf.replyToAddress" :state="(errors.replyToAddress?false:null)" type="text" />
          </div>
          <div class="form-group">
            <label for="invoicesReplyToAddress">Invoices reply-to address</label>
            <b-form-input id="invoicesReplyToAddress" v-model="conf.invoicesReplyToAddress" :state="(errors.invoicesReplyToAddress?false:null)" type="text" />
          </div>
          <div class="form-group">
            <label for="ccInvoicesAddres">CC all Invoices emails</label>
            <b-form-input id="ccInvoicesAddres" v-model="conf.ccInvoicesAddres" :state="(errors.ccInvoicesAddres?false:null)" type="text" />
          </div>
        </form>
      </b-card>
      <template v-slot:modal-footer="{ }">
        <b-button variant="dark" @click="closeModal">
          Close
        </b-button>
        <b-button type="submit" variant="success" @click="enableModuleLocal">
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit, faPlus, faTrash, faInfoCircle, faDownload
} from '@fortawesome/free-solid-svg-icons';

library.add(faEdit, faPlus, faTrash, faInfoCircle, faDownload);

export default {
  props: ['serviceModule', 'enableModule', 'orgServiceModule'],
  data () {
    return {
      errors: [],
      files: [],
      conf: {
        replyToAddress: '',
        invoicesReplyToAddress: '',
        ccInvoicesAddres: '',
      },
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  watch: {
    modules () {
      if (this.$store.getters.modules['booking_notifications']) {
        this.$http.get(`org-service-modules/${this.$store.getters.modules['booking_notifications'].id}`)
        .then((res) => {
          this.conf = JSON.parse(res.data.config);
        })
        .catch(this.err);
      }
    }
  },
  methods: {
    enableModuleLocal () {
      this.enableModule({ serviceModuleId: this.serviceModule.id, config: this.conf });
      this.$refs[`${this.serviceModule.key}conf`].hide();
    },
    closeModal () {
      if (!this.$store.getters.modules['invoices']) {
        this.serviceModule.enabled = false;
      }
      this.$refs[`${this.serviceModule.key}conf`].hide();
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    openModuleConfiguration () {
      this.$refs[`${this.serviceModule.key}conf`].show();
    },
  },
};
</script>
