<template>
  <div>
    <div class="btn btn-link">
      <fa-icon v-if="serviceModule.enabled" icon="cogs" @click="openModuleConfiguration" />
    </div>
    <b-modal :ref="`${serviceModule.key}conf`" size="lg" no-enforce-focus hide-header>
      <b-card title="Invoice options">
        <form @submit.prevent="enableModuleLocal">
          <div class="form-group">
            <label for="refPattern">Invoice reference number pattern
              <fa-icon id="ref-pattern-icon" v-b-tooltip.hover icon="info-circle" />
              <b-tooltip target="ref-pattern-icon" :title="refPatternDescription" />
            </label>
            <b-form-input id="refPattern" v-model="conf.refPattern" :state="(errors.refPattern?false:null)" type="text" />
          </div>
          <div class="form-group">
            <label for="deadlineDuration">Deadline length (in days)</label>
            <b-form-input id="deadlineDuration" v-model="conf.deadlineDuration" :state="(errors.deadlineDuration?false:null)" type="number" />
          </div>
          <div class="form-group">
            <label for="overduePenalty">Overdue penalty %</label>
            <b-form-input id="overduePenalty" v-model="conf.overduePenalty" :state="(errors.overduePenalty?false:null)" type="number" step="0.01" />
          </div>
          <div class="form-group">
            <label for="invoiceName">Invoice name</label>
            <b-form-input id="invoiceName" v-model="conf.invoiceName" />
          </div>
          <div class="form-group">
            <label for="primaryColor">Primary color</label>
            <b-form-input id="primaryColor" v-model="conf.primaryColor" />
          </div>
          <div>
            Invoice logo<span v-if="existingLogo.file">: {{ existingLogo.file.originalName }} <fa-icon icon="download" @click="downloadLogo" /></span>
          </div>
          <div class="custom-file">
            <input id="logo-file" ref="logoFileRef" type="file" class="custom-file-input" @change="imageSelected">
            <label class="custom-file-label" for="customFile">{{ fileLabel }}</label>
          </div>
        </form>
      </b-card>
      <template v-slot:modal-footer="{ }">
        <b-button variant="dark" @click="closeModal">
          Close
        </b-button>
        <b-button type="submit" variant="success" @click="enableModuleLocal">
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import * as FileDownload from 'js-file-download';
import {
  faEdit, faPlus, faTrash, faInfoCircle, faDownload
} from '@fortawesome/free-solid-svg-icons';

library.add(faEdit, faPlus, faTrash, faInfoCircle, faDownload);

export default {
  props: ['serviceModule', 'enableModule', 'orgServiceModule'],
  data () {
    return {
      existingLogo: {},
      errors: [],
      files: [],
      conf: {
        refPattern: '{{yyyy}}{{MM}}{{dd}}{{count}}',
        deadlineDuration: 20,
        overduePenalty: 0.5,
        primaryColor: 'black',
        invoiceName: 'Invoice'
      },
      fileLabelDefault: 'Choose logo to be displayed on invoices',
      fileLabel: 'Choose logo to be displayed on invoices',
      refPatternDescription: `
Replacement patterns:
{{dd}} - Current timestamp date
{{MM}} - Current timestamp month
{{yyyy}} - Current timestamp year
{{count}} - Invoice count #
`
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  watch: {
    modules () {
      if (this.$store.getters.modules['invoices']) {
        this.getExistingLogo();
        this.$http.get(`org-service-modules/${this.$store.getters.modules['invoices'].id}`)
        .then((res) => {
          this.conf = JSON.parse(res.data.config);
        })
        .catch(this.err);
      }
    }
  },
  methods: {
    getExistingLogo () {
      this.$http.get(`invoices/logo`)
      .then((res) => {
        this.existingLogo = res.data;
      }).catch(this.err);
    },
    downloadLogo () {
      this.$http({
        url: `invoices/logo/${this.existingLogo.id}`,
        method: 'GET',
        responseType: 'blob', // important
      })
      .then(res => {
        const disposition = res.headers['content-disposition'];
        const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
        FileDownload(res.data, filename);
      }).catch(this.err);
    },
    imageSelected () {
      this.files = this.$refs.logoFileRef.files;
      if (!this.files || this.files.length !== 1 || !this.isFileImage(this.files[0])) {
        this.fileLabel = this.fileLabelDefault;
        this.files = [];
        return this.err(new Error('Invalid image'));
      }
      this.fileLabel = this.files[0].name;
    },
    isFileImage (file) {
      const acceptedImageTypes = ['image/jpeg', 'image/png'];
      return file && acceptedImageTypes.includes(file['type']);
    },
    closeModal () {
      if (!this.$store.getters.modules['invoices']) {
        this.serviceModule.enabled = false;
      }
      this.$refs[`${this.serviceModule.key}conf`].hide();
    },
    enableModuleLocal () {
      if (this.files.length > 0) {
        // handle file changes
        const formData = new FormData();
        if (!this.files.length) {return;}
        // append the files to FormData
        Array
        .from(Array(this.files.length).keys())
        .map((x) => {
          formData.append('invoiceLogo', this.files[x], this.files[x].name);
          return formData;
        });
        // upload the file
        this.$http({
          method: 'post',
          url: '/invoices/logo',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } },
          timeout: (10000 * 60), // 10 min
        })
        .then(() => {
          return this.getExistingLogo();
        })
        .catch(this.err);
      }

      this.fileLabel = this.fileLabelDefault;
      this.files = [];
      this.$refs[`${this.serviceModule.key}conf`].hide();
      this.enableModule({ serviceModuleId: this.serviceModule.id, config: this.conf });
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    openModuleConfiguration () {
      this.$refs[`${this.serviceModule.key}conf`].show();
    },
  },
};
</script>
