<template>
  <b-card v-if="modules && !!modules['invoices']" title="Bank accounts">
    <blueprint-data api-route-path="/bank-accounts" :fields="fields" no-search>
      <template v-slot:default="{ state, item }">
        <b-form-group label="Name" label-for="name">
          <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Acount name" />
        </b-form-group>
        <b-form-group label="Bank name" label-for="bank-name">
          <b-form-input id="bank-name" v-model="item.bankName" :state="state('bankName')" type="text" placeholder="Bank name" />
        </b-form-group>
        <b-form-group label="IBAN" label-for="iban">
          <b-form-input id="iban" v-model="item.iban" :state="state('iban')" type="text" placeholder="IBAN" />
        </b-form-group>
        <b-form-group label="Swift" label-for="name">
          <b-form-input id="swift" v-model="item.swift" :state="state('swift')" type="text" placeholder="Swift" />
        </b-form-group>
        <b-form-group label="BIC" label-for="bic">
          <b-form-input id="bic" v-model="item.bic" :state="state('bic')" type="text" placeholder="BIC" />
        </b-form-group>
      </template>
    </blueprint-data>
  </b-card>
</template>

<script>
export default {
  data () {
    return {
      errors: {},
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'bankName',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
};
</script>
