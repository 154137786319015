<template>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-12 col-xs-12">
      <b-card title="Organization">
        <form @submit.prevent="save">
          <div class="form-group">
            <label for="alias">Organization ID/alias</label>
            <b-form-input id="alias" v-model="org.alias" disabled :state="(errors.alias?false:null)" type="text" placeholder="Org ID" />
          </div>
          <div class="form-group">
            <label for="name">Name</label>
            <b-form-input id="name" v-model="org.name" :state="(errors.name?false:null)" type="text" placeholder="Name" />
          </div>
          <div class="form-group">
            <label for="street">Steet</label>
            <b-form-input id="street" v-model="org.street" :state="(errors.street?false:null)" type="text" placeholder="Street" />
          </div>
          <div class="form-group">
            <label for="city">City</label>
            <b-form-input id="city" v-model="org.city" :state="(errors.city?false:null)" type="text" placeholder="City" />
          </div>
          <div class="form-group">
            <label for="zip">Zip</label>
            <b-form-input id="zip" v-model="org.zip" :state="(errors.zip?false:null)" type="text" placeholder="Zip" />
          </div>
          <div class="form-group">
            <label for="state">State</label>
            <b-form-input id="state" v-model="org.state" :state="(errors.state?false:null)" type="text" placeholder="State" />
          </div>
          <div class="form-group">
            <label for="country">Country</label>
            <b-form-input id="country" v-model="org.country" :formatter="toUpperCase" :state="(errors.country?false:null)" type="text" placeholder="ISO 3166 Country Code" />
          </div>
          <div class="form-group">
            <label for="vatno">VAT No.</label>
            <b-form-input id="vatno" v-model="org.vatno" :state="(errors.vatno?false:null)" type="text" placeholder="VAT No." />
          </div>
          <div class="form-group">
            <label for="vatno">Firebase credential JSON (push notify)</label>
            <b-form-textarea
              id="firebaseCredential"
              v-model="org.firebaseCredential" rows="3" max-rows="6" :state="(errors.firebaseCredential?false:null)"
            />
          </div>
          <h5>Public information</h5>
          <div class="form-group">
            <label for="allowUsersRegistration">Allow users to self signup</label>
            <b-form-checkbox v-model="org.allowUsersRegistration" size="lg" switch />
          </div>
          <div class="form-group">
            <label for="publicListing">Allow public available bookable units and properties listing</label>
            <b-form-checkbox v-model="org.publicListing" size="lg" switch />
          </div>
          <div class="form-group">
            <label for="publicListing">Allow public booking</label>
            <b-form-checkbox v-model="org.publicBooking" size="lg" switch />
          </div>
          <div class="text-center">
            <b-button type="submit" variant="success">
              Save
            </b-button>
          </div>
        </form>
      </b-card>
    </div>
    <div class="col-lg-6 col-md-12 col-xs-12">
      <b-card title="Service modules">
        <div v-for="serviceModule in serviceModules" :key="serviceModule.id">
          <div class="row">
            <div class="col col-lg-10">
              <label>{{ serviceModule.name }}</label><br>
              <label><small>{{ serviceModule.description }}</small></label>
            </div>
            <div class="col col-lg-2 text-right align-middle">
              <component
                :is="serviceModuleConfComponents[serviceModule.key]"
                v-if="serviceModuleConfComponents[serviceModule.key]"
                :ref="`${serviceModule.key}conf`"
                href="#"
                :service-module="serviceModule"
                :enable-module="enableServiceModule"
                :org-service-module="serviceModule.osm"
                @click.prevent="openModuleConfiguration(serviceModule.key)"
              />
              <b-form-checkbox v-model="serviceModule.enabled" size="lg" switch @change="switchServiceModule(serviceModule, $event)" />
            </div>
          </div>
        </div>
      </b-card>
      <BankAccounts />
    </div>
  </div>
</template>

<script>
import InvoicesConfigurations from '../../../service-modules/invoices/components/module-configuration.vue';
import BookingNotificationsConfigurations from '../../../service-modules/booking_notifications/components/module-configuration.vue';
import BankAccounts from '../../../service-modules/invoices/components/bank-accounts.vue';

export default {
  components: {
    InvoicesConfigurations,
    BankAccounts,
    BookingNotificationsConfigurations
  },
  data () {
    return {
      org: {
        alias: '',
        name: '',
        street: '',
        zip: '',
        city: '',
        state: '',
        country: '',
        vatno: '',
      },
      serviceModules: [],
      orgServiceModules: [],
      errors: {},
      serviceModuleConfComponents: {
        invoices: InvoicesConfigurations,
        'booking_notifications': BookingNotificationsConfigurations,
      },
    };
  },
  created () {
    this.getOrg();
    this.getServiceModules().then(() => this.getOrgServiceModules());
  },
  methods: {
    success (title, text) {
      this.$notify({
        title,
        text,
        type: 'success',
      });
    },
    error (title, text) {
      this.$notify({
        title,
        text,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    toUpperCase (value) {
      return value.toUpperCase();
    },
    getOrg () {
      this.$http.get('org')
        .then((res) => {
          this.org = res.data;
        })
        .catch(this.err);
    },
    getServiceModules () {
      return this.$http.get('service-modules')
        .then((res) => {
          this.serviceModules = res.data.map(sm => ({ ...sm, enabled: false }));
        })
        .catch(this.err);
    },
    getOrgServiceModules () {
      return this.$http.get('org-service-modules')
        .then((res) => {
          this.orgServiceModules = res.data;
          this.orgServiceModules.forEach((osm) => {
            const sm = this.serviceModules.find(serviceModule => osm.serviceModuleId === serviceModule.id);
            if (sm) {
              sm.orgServiceModuleId = osm.id;
              sm.enabled = true;
              sm.osm = osm;
            }
          });
        })
        .catch(this.err);
    },
    disableServiceModule ({ orgServiceModuleId }) {
      this.$notify({ text: 'Disabling module and refreshing page...' });
      this.$http.delete(`org-service-modules/${orgServiceModuleId}`)
        .then(() => {
          location.reload();
        })
        .catch(this.err);
      //.finally(this.getOrgServiceModules);
    },
    enableServiceModule ({ serviceModuleId, config }) {
      const sm = this.serviceModules.find(sm => serviceModuleId === sm.id);
      const osm = this.orgServiceModules.find(osm => serviceModuleId === osm.serviceModuleId);
      if (sm.enabled && osm) {
        this.$http.patch(`org-service-modules/${osm.id}`, { serviceModuleId, config: JSON.stringify(config) })
        .catch((err) => {
          sm.enabled = false;
          return this.err(err);
        })
        .finally(this.getOrgServiceModules);
      } else {
        this.$notify({ text: 'Enabling module and refreshing page...' });
        this.$http.post('org-service-modules', { serviceModuleId, config: JSON.stringify(config) })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          sm.enabled = false;
          return this.err(err);
        });
        //.finally(this.getOrgServiceModules);
      }
    },
    switchServiceModule (serviceModule, enable) {
      if (enable) {
        if (this.$refs[`${serviceModule.key}conf`]) {
          this.openModuleConfiguration(serviceModule);
        } else {
          this.enableServiceModule({ serviceModuleId: serviceModule.id });
        }
      } else {
        this.disableServiceModule({ orgServiceModuleId: serviceModule.orgServiceModuleId });
      }
    },
    save () {
      this.$http.patch('org', this.org)
        .then(() => {
          this.errors = {};
          this.success('Done', 'Organization saved');
          return this.getOrg();
        })
        .catch(this.err);
    },
    moduleHasConfiguration (serviceModule) {
      const conf = this.$refs[`${serviceModule.key}conf`];
      return conf && conf[0];
    },
    openModuleConfiguration (serviceModule) {
      const conf = this.$refs[`${serviceModule.key}conf`];
      if (conf && conf[0]) {
        conf[0].openModuleConfiguration();
      }
    },
  },
};
</script>
